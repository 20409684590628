import React, { useEffect, useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { FaPlus, FaMinus, FaTrash } from "react-icons/fa";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const userId = localStorage.getItem("userId");
  const userToken = localStorage.getItem("token");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch(`http://localhost:5000/cart/find/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        token: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCartItems(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching cart items:", error);
        setLoading(false);
        setError("Error fetching cart items");
      });
  }, [userId, userToken]);

  useEffect(() => {
    const fetchProducts = async () => {
      const productData = [];
      for (const obj of cartItems) {
        for (const productObj of obj.products) {
          try {
            const response = await fetch(
              `https://e-commerce-api-ealr.onrender.com/product/find/${productObj?.productId}`
            );
            const data = await response.json();
            productData.push(data);
          } catch (error) {
            console.error("Error fetching product data:", error);
          }
        }
      }
      setProducts(productData);
      setLoading(false);
    };

    fetchProducts();
  }, [cartItems]);
  // const totalCost = products.reduce((acc, item) => {
  //   return acc + (item.price || 0);
  // }, 0);

  const handleIncreaseQuantity = async (productId) => {
    try {
      const response = await fetch(
        `https://e-commerce-api-ealr.onrender.com/cart/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            productId,
            quantity: 1,
          }),
        }
      );

      if (response.ok) {
        console.log("Quantity increased successfully");
        fetchCartData();
      } else {
        console.error("Failed to increase quantity");
      }
    } catch (error) {
      console.error("Error increasing quantity:", error);
    }
  };

  const handleDecreaseQuantity = async (productId) => {
    try {
      const response = await fetch(
        `https://e-commerce-api-ealr.onrender.com/cart/${userId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            productId,
            quantity: -1,
          }),
        }
      );

      if (response.ok) {
        console.log("Quantity decreased successfully");
        fetchCartData();
      } else {
        console.error("Failed to decrease quantity");
      }
    } catch (error) {
      console.error("Error decreasing quantity:", error);
    }
  };
  const fetchCartData = async () => {
    try {
      const response = await fetch(
        `https://e-commerce-api-ealr.onrender.com/cart/find/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${userToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCartItems(data);
      } else {
        console.error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const handleRemoveProduct = async (productId) => {
    try {
      const response = await fetch(
        `https://e-commerce-api-ealr.onrender.com/cart/delete/${productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${userToken}`,
          },
        }
      );
  
      if (response.ok) {
        console.log("Product deleted successfully");
      } else {
        console.error("Failed to delete product");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };
  
  return (
    // <div><h1>this is Cart</h1></div>
    <div className="cart-container">
      <h2>Your Cart</h2>
      {/* <h3>Total Cost: {totalCost.toFixed(2)}</h3> */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {error ? (
            <p>{error}</p>
          ) : (
            <div className="cart-items">
              {products?.map((item, key) => (
                <div className="cart-item">
                  <img src={item?.img} alt={item?.title} />
                  <h3>{item?.title}</h3>
                  <h4>{item?.desc}</h4>
                  {/* <h5>Price: {item?.price}</h5> */}
                  <div className="quantity-controls">
                    <button onClick={() => handleDecreaseQuantity(item._id)}>
                      <FaMinus />
                    </button>
                    <h3 className="quantity">{item?.quantity}</h3>
                    <button onClick={() => handleIncreaseQuantity(item._id)}>
                      <FaPlus />
                    </button>
                    <button
                      className="delete-btn"
                      onClick={() => handleRemoveProduct(item._id)}
                    >
                      <FaTrash />
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {/* <Link to={{ pathname: "/checkout", state: { totalCost } }}>
        <button>Proceed to Checkout</button>
      </Link> */}
    </div>
  );
};

export default Cart;
