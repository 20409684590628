import React, { useState } from "react";
import "./checkout.css";
import { useLocation, useNavigate } from "react-router-dom";

const Checkout = () => {
  const location = useLocation();
  const totalCost = location.state && location.state.totalCost;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const [showMessage, setShowMessage] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.address || !formData.cardNumber || !formData.expiryDate || !formData.cvv) {
      alert("Please fill in all required fields");
      return;
    }
    simulateSuccessfulPurchase();
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
     navigate("/products");
    }, 3000);
  };

  const simulateSuccessfulPurchase = () => {
    console.log("Simulating a successful purchase");
  };

  return (
    <>
    <div className="checkout-container">
      <h2>Checkout</h2>
      <p>Total Cost: {totalCost}</p>
      <div className="checkout-form">
      <form onSubmit={handleSubmit} className="checkout-form">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            placeholder="techEagle"
            value={formData.name}
            onChange={handleChange}
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            placeholder="tech@gmail.com"
            value={formData.email}
            onChange={handleChange}
          />

          <label htmlFor="address">Address:</label>
          <textarea
            id="address"
            placeholder="Enter your address"
            value={formData.address}
            onChange={handleChange}
          ></textarea>

          <label htmlFor="cardNumber">Card Number:</label>
          <input
            type="text"
            id="cardNumber"
            placeholder="**** **** **** ****"
            value={formData.cardNumber}
            onChange={handleChange}
          />

          <label htmlFor="expiryDate">Expiry Date:</label>
          <input
            type="text"
            id="expiryDate"
            placeholder="MM/YY"
            value={formData.expiryDate}
            onChange={handleChange}
          />

          <label htmlFor="cvv">CVV:</label>
          <input
            type="text"
            id="cvv"
            placeholder="123"
            value={formData.cvv}
            onChange={handleChange}
          />

          <button type="submit" className="checkout-button">
            Complete Purchase
          </button>
        </form>
      </div>
    </div>
    {showMessage && (
  <div className={`purchase-success-message ${showMessage ? "show" : ""}`}>
    <p>Purchase successful!</p>
  </div>
)}

    </> );
};

export default Checkout;
